import React from 'react';
import { Field, ErrorMessage } from 'formik';

export const FormikField = React.forwardRef(({name, label, type, value, custom}, ref) => {
    return (
    <div>
        <label htmlFor={name}>{label}</label>
        <span className="authform--errorfield">
            <ErrorMessage name={name} />
        </span>
        <Field name={name} type={type} value={value} />
        {custom}
    </div>
    );
});
