import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Amplitude from 'amplitudejs';
import CircularEqualizer from './PlayerViz';
import {ReactComponent as CirclesSvg} from './img/circles.svg';
import {ReactComponent as CachingWavesSvg} from './img/caching_waves.svg';
import {ReactComponent as CachingSvg} from './img/caching.svg';
import {ReactComponent as PlaySvg} from './img/play.svg';
import {ReactComponent as StopSvg} from './img/stop.svg';
import {ReactComponent as LinksCirclesSvg} from './img/circles_quality.svg';
import {ReactComponent as LinksCircleWaveSvg} from './img/circles_quality_wave.svg';
import '../../scss/player/Player.scss';
import '../../scss/player/PlayerCircles.scss';
import '../../scss/player/PlayerQuality.scss';

export default function Player() {
    const { t } = useTranslation();
    const [ qualityName, setQualityName ] = useState(t('low_stream_name'));
    const [ qualityProps, setQualityProps ] = useState(t('low_stream_quality'));
    const [ streamUrl, setStreamUrl ] = useState('');
    const [ qualityTrigger, setQualityTrigger ] = useState(false);

    useEffect(() => {
        if (Amplitude.getActiveIndex() == 0) {
            setQualityName(t('low_stream_name'));
            setQualityProps(t('low_stream_quality'));
        } else {
            setQualityName(t('high_stream_name'));
            setQualityProps(t('high_stream_quality'));
        }
        setStreamUrl(Amplitude.getActiveSongMetadata()['url']);
    }, [t, qualityTrigger]);

    useEffect(() => {
        Amplitude.init({
            songs: [{
                "name": 'low',
                "url": process.env.REACT_APP_LOW_STREAM_URL,
                "live": true
            }, {
                "name": 'high',
                "url": process.env.REACT_APP_HIGH_STREAM_URL,
                "live": true
            }],
            preload: "none",
            visualizations: [
                {
                    object: CircularEqualizer,
                    params: {}
                }
            ],
            visualization: 'circular_equalizer',
            callbacks: {
                play: function() {
                    document.getElementById("stream-player")
                        .classList.add("stream-player--caching");
                },
                stop: function() {
                    const player = document.getElementById("stream-player");
                    player.classList.remove("stream-player--playing");
                    player.classList.remove("stream-player--caching");
                },
                playing: function() {
                    const player = document.getElementById("stream-player");
                    player.classList.remove("stream-player--caching");
                    player.classList.add("stream-player--playing");
                }
            },
            debug: false
        });
        Amplitude.setGlobalVisualization('circular_equalizer');
        setQualityTrigger(q => !q);
    }, []);

    const toggleQuality = quality => {
        var off, on;
        if (quality === 'low') {
            [off, on] = ["connect-high", "connect-low"];
            Amplitude.playSongAtIndex(0);
        } else {
            [on, off] = ["connect-high", "connect-low"];
            Amplitude.playSongAtIndex(1);
        }

        document.getElementById(off)
            .classList.remove("connection--link-active");
        document.getElementById(on)
            .classList.add("connection--link-active");

        setQualityTrigger(!qualityTrigger);
    }

    return (<>
        <div id='stream-player'>
            <Circles />
            <div className="amplitude-visualization"></div>
            <button type='button' className="amplitude-button amplitude-play" >
                <PlaySvg />
            </button>
            <button type='button' className="amplitude-button amplitude-stop" >
                <StopSvg />
            </button>
            <button type='button' className="amplitude-button amplitude-caching" onClick={() => Amplitude.stop()}>
                <CachingSvg />
            </button>
            <CachingWavesSvg />
        </div>
        <span className="stream-player-quality">{ qualityName }<br /><i>{ qualityProps }</i></span>
        <span className="stream-player-quality"><a href={ streamUrl } rel="nofollow">{ t('link') }</a></span>
        <button id="connect-low" className="connection--link connection--link-active" onClick={() => toggleQuality('low')}>
            <LinksCircleWaveSvg className="svg-waves" />
            <LinksCirclesSvg className="svg-circles" />
            <span>{t('low')}</span>
        </button>
        <button id="connect-high" className="connection--link" onClick={() => toggleQuality('high')}>
            <LinksCircleWaveSvg className="svg-waves" />
            <LinksCirclesSvg className="svg-circles" />
            <span>{t('high')}</span>
        </button>
    </>);
}

function Circles() {
    return (
        <CirclesSvg />
    )
}
