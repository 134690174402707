import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Bottom from './Bottom';
import Header from './Header';
import OAuth from './OAuth';
import { FormikField } from './FormikField';
import '../scss/Login.scss';

export default function Login() {
    return (
        <>
        <div id='Content'>
            <Header />
            <SignInForm />
        </div>
            <Bottom />
        </>
    );
}

export function request_veriry_token(email) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL + '/request-verify-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({ email: email })
        })
        .then(response => {
            if (response.ok) {
                resolve();
            } else {
                return response.json();
            }
        })
        .then(response => {
            if (response.detail) {
                reject(response.detail)
            } else {
                reject();
            }
        })
        .catch(error => {
            reject(error);
        });
    });
}

function SignInForm() {
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState();
    const [ verificationSent, setVerificationSent ] = useState(false);
    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.password !== values.passwordConfirm) {
            setError(t('passwords-not-match'));
            setSubmitting(false);
            return;
        }
        fetch(process.env.REACT_APP_API_URL + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({
                username: values.username,
                password: values.password,
                email: values.email,
                language: i18n.language
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.email) {
                    setVerificationSent(true);
                    toast.promise(request_veriry_token(response.email), {
                        pending: t('sending-verification'),
                        success: t('verification-sent'),
                        error: {
                            render({data}) {
                                if (!data) {
                                    data = t('couldnt-sent-verification');
                                }
                                return data;
                            }
                        }
                    });
                } else if (response.detail) {
                    if (typeof response.detail == 'object') {
                        if (response.detail.reason) {
                            setError(response.detail.reason);
                        } else {
                            setError(JSON.stringify(response.detail));
                        }
                    } else {
                        setError(response.detail);
                    }
                } else {
                    setError(t('sign-in-error'));
                }
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <div id="SignIn" className="authform">
            {!verificationSent &&
            <>
                <h2>{t('new-user-registration')}</h2>
                <Formik 
                    initialValues={{ 
                        username: '',
                        email: '',
                        password: '',
                        passwordConfirm: ''}}
                    validationSchema={Yup.object({
                        username: Yup.string()
                            .min(3, t('must-be-3-15-char'))
                            .max(15, t('must-be-3-15-char'))
                            .required('Required'),
                        password: Yup.string()
                            .min(8, t('must-be-8-50-char'))
                            .max(50, t('must-be-8-50-char'))
                            .required('Required'),
                        email: Yup.string()
                            .email(t('invalid-email'))
                            .required('Required'),
                    })}
                    onSubmit={handleSubmit}
                >
                {({ isSubmitting }) => (
                <Form>
                    <div className="auth--error">{error}</div>

                    <FormikField name="username" label={t('username')} type="text" />
                    <FormikField name="email" label="Email" type="email" />
                    <FormikField name="password" label={t('password')} type="password" />
                    <FormikField name="passwordConfirm" label={t('confirm-password')} type="password" />

                    <div className="auth--button">
                        <button type="submit" disabled={isSubmitting}>{t('sign-in')}</button>
                    </div>
                </Form>
                )}
                </Formik>
                <OAuth />
            </>
            }
            {verificationSent && 
                <div className="auth-message">
                    {t('verification-code-sent')}
                </div>
            }
        </div>
    )
}
