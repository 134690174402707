import { HashRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactToolTip from "react-tooltip";
import App from './App';
import Login from './Login';
import SignIn from './SignIn';
import Verify from './Verify';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import { UserProvider } from './UserProvider';
import HowToListen from './HowToListen';
import 'react-toastify/dist/ReactToastify.css';
import '../scss/Radiost.css';

function Radiost() {
    return (
        <div id="Radiost">
            <UserProvider>
                <ReactToolTip place="right" effect="float" className="tooltip"
                    event='mousedown mouseover touchstart'
                    eventOff="mouseup mouseout touchend" />
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/verify" element={<Verify />} />
                        <Route path="/new-password" element={<NewPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/how-to-listen" element={<HowToListen />} />
                    </Routes>
                </HashRouter>
                <ToastContainer theme="dark" />
            </UserProvider>
        </div>
    );
}

export default Radiost;
