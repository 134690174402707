import Bottom from './Bottom';
import Header from './Header';
import Connection from './Connection';
import Disclamer from './Disclamer';
import Tracklist from './Tracklist';
import NowPlayingPanel from './NowPlayingPanel';
import ShowsTimeTable from './ShowsTimeTable';
import { TrackProvider } from './TrackProvider';

export default function App() {
    return (
        <>
        <div id='Content'>
            <Header />
            <Connection />
            <TrackProvider>
                <Tracklist />
                <NowPlayingPanel />
            </TrackProvider>
            <ShowsTimeTable />
            <Disclamer />
        </div>
            <Bottom />
        </>
    );
}
