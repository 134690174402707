import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormikField } from './FormikField';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Bottom from './Bottom';
import Header from './Header';
import { useUser } from './UserProvider';
import '../scss/Login.scss';

export default function Login() {
    return (
        <>
            <Header />
            <NewPassword />
            <Bottom />
        </>
    );
}

function NewPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user, refreshUser } = useUser();
    const token = searchParams.get('token');
    const [ result, setResult ] = useState()
    const [ error, setError ] = useState()
    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.password !== values.passwordConfirm) {
            setError(t('passwords-not-match'));
            setSubmitting(false);
            return;
        }
        fetch(process.env.REACT_APP_API_URL + '/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({token: token, password: values.password})
        })
        .then(response => {
            if (response.ok) {
                navigate('/login')
            } else {
                return response.json();
            }
        })
        .then(response => {
            if (response.detail) {
                setError(JSON.stringify(response['detail']));
            } else {
                setError('Error');
            }
        })
        .catch(error => {
            setError(error);
        })
        .finally(() => setSubmitting(false));
    };
    return (
        <div id="NewPassword" className="authform">
            <h2>{t('password-reset')}</h2>
            <Formik 
                initialValues={{ 
                    password: '',
                    passwordConfirm: ''}}
                validationSchema={Yup.object({
                    password: Yup.string()
                        .min(8, t('must-be-8-50-char'))
                        .max(50, t('must-be-8-50-char'))
                        .required('Required')
                })}
                onSubmit={handleSubmit}
            >
            {({ isSubmitting }) => (
            <Form>
                <div className="auth--error">{error}</div>

                <FormikField name="password" label={t('password')} type="password" />
                <FormikField name="passwordConfirm" label={t('confirm-password')} type="password" />

                <div className="auth--button">
                    <button type="submit" disabled={isSubmitting}>{t('reset')}</button>
                </div>
            </Form>
            )}
            </Formik>
        </div>

    );
}
