function isJson(response) {
    return response.headers.get('Content-Type').includes('application/json');
}

export function PromiseFetch(url, params) {
    return new Promise((resolve, reject) => fetch(process.env.REACT_APP_API_URL + url, params)
        .then(response => {
            if (response.ok) {
                if (isJson(response)) {
                    response.json().then(json => resolve(json));
                } else {
                    response.text().then(text => resolve(text));
                }
            } else {
                if (isJson(response)) {
                    response.json().then(json => {
                        if (json.detail) {
                            reject(json.detail);
                        } else {
                            reject(JSON.stringify(json));
                        }
                    });
                } else {
                    reject(response.statusText);
                }
            };
        })
        .catch(error => reject(error.message)))
}
