import React, { Component } from "react";

export default class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remaining: Math.floor(props.initialCountdown),
        };
        this.componentClass = props.css
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                remaining: prevState.remaining > 0 ? prevState.remaining - 1 : 0,
            }));
        }, 1000);
    }

    componentDidUpdate() {
        const { remaining } = this.state;
        if (remaining < 1) {
            clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { remaining } = this.state;

        const options = {
            timeZone: 'Etc/UTC',
            hour12: false,
            minute: "2-digit",
            second: "2-digit"
        }
        if (remaining > 3600) {
            options['hour'] = '2-digit';
        }
        if (remaining === undefined || isNaN(remaining)) {
            return <></>
        }
        return (
            <span className={this.componentClass}>
            {
                new Intl.DateTimeFormat("en-GB", options).format(
                    new Date(remaining * 1000))
            }
            </span>
        );
    }
}
