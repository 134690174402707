import Amplitude from 'amplitudejs';

export default function CircularEqualizer() {
    this.id = "circular_equalizer";
    this.name = "Circular Equalizer";
    this.container = "";
    this.preferences = {
        barWidth: 3,
        barHeight: 8,
        barSpacing: 1,
        barColor: "#99c1f1",
        shadowColor: "#ffffff",
        shadowBlur: 10
    };
    this.analyser = "";
    this.frequencyData = [];
    this.canvas = null;
    this.canvasCtx = null;
    this.gradient = null;
    this.getID = function() {
        return this.id
    };
    this.getName = function() {
        return this.name
    };
    this.setPreferences = function(t) {
        for (var e in this.preferences) null != t[e] && (this.preferences[e] = t[e])
    };
    this.startVisualization = function(t) {
        this.analyser = Amplitude.getAnalyser();
        this.container = t;
        window.cont = this.container;
        this.canvas = document.createElement("canvas");
        this.canvas.width = this.container.offsetWidth;
        this.canvas.height = this.container.offsetHeight;
        this.container.appendChild(this.canvas);
        this.canvasCtx = this.canvas.getContext("2d");
        this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        this.gradient = this.canvasCtx.createLinearGradient(0, 0, 0, 400);
        this.gradient.addColorStop(0, this.preferences.barColor);
        this.gradient.addColorStop(1, "orange");
        this.canvasCtx.fillStyle = this.gradient;
        this.canvasCtx.shadowBlur = this.preferences.shadowBlur;
        this.canvasCtx.shadowColor = this.preferences.shadowColor;
        requestAnimationFrame(this.renderFrame.bind(this));
    };
    this.stopVisualization = function() {
        this.container.innerHTML = "";
        window.cancelAnimationFrame(this.renderFrame.bind(this));
    };
    this.renderFrame = function() {
        requestAnimationFrame(this.renderFrame.bind(this));
        this.analyser.getByteFrequencyData(this.frequencyData);
        this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for (var t = this.canvas.width / 2, e = this.canvas.height / 2, a = t - 27, i = Math.floor(2 * a * Math.PI / (this.preferences.barWidth + this.preferences.barSpacing)), s = i - Math.floor(25 * i / 100), n = Math.floor(this.frequencyData.length / i), r = 0; r < s; r++) {
            var h = this.frequencyData[r * n],
                c = 2 * r * Math.PI / i + Math.PI,
                o = (135 - this.preferences.barWidth) * Math.PI / 180,
                f = a - (this.preferences.barHeight),
                l = this.preferences.barWidth,
                d = h / 6 + this.preferences.barHeight;
            this.canvasCtx.save();
            this.canvasCtx.translate(t, e);
            this.canvasCtx.rotate(c - o);
            this.canvasCtx.fillRect(0, f, l, d);
            this.canvasCtx.restore();
        }
    }
}
