import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Bottom from './Bottom';
import Header from './Header';
import { useUser } from './UserProvider';
import '../scss/Login.scss';

export default function Login() {
    return (
        <>
            <Header />
            <Verify />
            <Bottom />
        </>
    );
}

function Verify() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { user, refreshUser } = useUser();
    const token = searchParams.get('token');
    const [ result, setResult ] = useState()
    const [ error, setError ] = useState()
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({token: token})
        })
        .then(response => {
            if (response.ok) {
                setResult(t('successfully-verified'));
                refreshUser();
            } else {
                return response.json();
            }
        })
        .then(response => setError(response['detail']));
    }, [token, refreshUser, t]);
    return (
        <div className="authform">
            <h2>{t('verifying-email')}</h2>
            {result && 
                <>
                    <p>{result}</p>
                    {!user &&
                        <Link to="/login">{t('login')}</Link>
                    }
                    {user &&
                        <Link to="/">{t('go_home')}</Link>
                    }
                </>}
            {error && 
                    <div className="auth--error">
                        <p>{error}</p>
                        <Link to="/">{t('go_home')}</Link>
                    </div>}
        </div>
    );
}
