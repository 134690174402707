import React, { createContext, useContext, useState } from 'react';

const TrackContext = createContext();
export const useTrack = () => useContext(TrackContext);

export function TrackProvider({ children }) {
    const [ tracklist, setTracklist ] = useState({"items": []});
    return (
        <TrackContext.Provider value={{ tracklist, setTracklist }}>
            {children}
        </TrackContext.Provider>
    );
};
