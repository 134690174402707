import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../scss/ShowsTimeTable.scss';

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default function ShowsTimeTable() {
    const { t } = useTranslation();
    const [ timetable, setTimeTable ] = useState([]);
    const [ update, setUpdate ] = useState(true);
    const [ timeoutId, setTimeoutId ] = useState();

    useEffect(() => {
        if (!update) return;
        setUpdate(false);
        fetch(process.env.REACT_APP_API_URL + '/shows/timetable', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(response => {
                setTimeTable(response);
                if (response.length > 0) {
                    response.map((tt) => tt.weekday = new Date(tt.time_to_play).getDay());
                    const time = new Date(
                        response[0].time_to_play) - new Date().getTime() + 1000;
                    setTimeoutId(setTimeout(setUpdate, time, true));
                }
            })
            .catch(console.error);
    }, [update, setUpdate]);

    const handleTimeTableVisibilityChange = () => {
        if (!document.hidden) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setUpdate(true);
        }
    }
    document.addEventListener("visibilitychange", handleTimeTableVisibilityChange);

    return (
        <div id="TimeTable">
            <div className="tt--entries">
            {timetable.map((tt, index, array) => (
                <React.Fragment key={tt.name + '-' + tt.weekday + tt.time_to_play}>
                {index == 0 || array[index - 1].weekday != tt.weekday
                    ? <div className="tt-weekdayname">{t(weekdays[tt.weekday])}</div> 
                    : <></>}
                        <div className="tt-entry">
                        <div className="tt-time">{convertTimeToLocalTZ(tt.time_to_play)}:</div>
                        <div className="tt-entryname">
                            {tt.performer != "" ? tt.performer + " - " : ""}{tt.name}
                        </div>
                    </div>
                </React.Fragment>
            ))}
            </div>
        </div>
    );
}

function convertTimeToLocalTZ(utctime) {
    return new Date(utctime).toLocaleTimeString(
        [], { hour: '2-digit', minute: '2-digit' });
}
