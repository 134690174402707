import { useTranslation } from "react-i18next";
import { AiFillFacebook } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import google from './sso-buttons/google/normal.png';

export default function OAuth() {
    const { t } = useTranslation();
    const oAuth = (provider) => {
        fetch(process.env.REACT_APP_API_URL + "/auth/" + provider + "/authorize")
            .then(response => response.json())
            .then(response => window.location.href = response.authorization_url);
    };

    const oAuthGoogle = () => oAuth("google");
    const oAuthFacebook = () => oAuth("facebook");
    const oAuthDiscord = () => oAuth("discord");

    return <>
        <h2>{t('or-with')}:</h2>
        <div class="authform--oauthlist">
            <button class="google" onClick={oAuthGoogle}><img src={google} alt="Google SSO" /></button>
            <button class="image-with-label facebook" onClick={oAuthFacebook}>
                <AiFillFacebook />
                <span>Facebook</span>
            </button>
            <button class="image-with-label discord" onClick={oAuthDiscord}>
                <BsDiscord />
                <span>Discord</span>
            </button>
        </div>
    </>
}
