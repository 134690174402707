import React, { useEffect, useState } from "react";
import { useTrack } from './TrackProvider';
import { useTranslation } from "react-i18next";
import Countdown from './Countdown';
import '../scss/NowPlayingPanel.scss';

export default function NowPlayingPanel() {
    const { t } = useTranslation();
    const { tracklist } = useTrack();
    const [ updateTrack, setUpdateTrack ] = useState(false);
    const [ updateShowTrack, setUpdateShowTrack ] = useState(false);
    const [ showTracklist, setShowTracklist ] = useState()
    const [ remainTime, setRemainTime ] = useState();
    const [ timeoutId, setTimeoutId ] = useState();

    useEffect(() => {
        setUpdateTrack(u => !u);
    }, [tracklist]);

    const [ track, setTrack ] = useState();
    const highlightArtists = artist => {
        const seps = [' and ', ' vs ', ' feat. '];
        const split = (name, seps) => {
            const res = [].concat.apply([], name.map(n => n.split(seps[0])));
            if (seps.length > 0) {
                return split(res, seps.slice(1))
            }
            return res;
        }
        split([artist], seps).forEach(name => {
            const link = `https://google.com/search?q=${name.replace(' ', '+')}+music`;
            artist = artist.replace(name, `<a href="${link}" target="window">${name}</a>`);
        });
        return artist;
    }

    const fetchAndHighlightRemixer = title => {
        const match = title.match(/\((.*) (remix|club mix|edit|bootleg|dubmix|mashup|pure mix|retouch|remake)\)/);
        if (match) {
            const result = highlightArtists(match[1]);
            return title.replace(match[1], result);
        }
        return title;
    }

    const secondsToTime = seconds => {
        var hours = showTracklist.current_duration >= 3601
            ? Math.floor(seconds / 3600).toString().padStart(2, '0') + ":" 
            : "";
        var minutes = Math.floor((seconds % 3600) / 60);
        var remainingSeconds = seconds % 60;

        return hours + minutes.toString().padStart(2, '0') + ":" + remainingSeconds.toString().padStart(2, '0');
    }

    const limitString = str => {
        if (str.length > 72) {
            return str.slice(0, 72) + '...';
        }
        return str;
    }

    useEffect(() => {
        if (updateTrack) {
            fetch(process.env.REACT_APP_API_URL + '/queue/playing')
                .then(response => response.json())
                .then(response => {
                    setTrack(response);
                    setUpdateTrack(false);
                    if (response.show_tracklist) {
                        setShowTracklist(response.show_tracklist)
                    }
                })
                .catch(console.error);
        }
    }, [updateTrack]);

    const handlePanelVisibilityChange = () => {
        if (!document.hidden) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setUpdateTrack(true);
        }
    }
    document.addEventListener("visibilitychange", handlePanelVisibilityChange);

    useEffect(() => {
        if (showTracklist) {
            setUpdateShowTrack(false);
            showTracklist.items.forEach((showtrack, index) => {
                if (showTracklist.current_id == showtrack.id) {
                    let remain;
                    if (index < showTracklist.items.length - 1) {
                        const diff = showtrack.play_time > showTracklist.current_play_time
                            ? showtrack.play_time
                            : showTracklist.current_play_time;
                        remain = showTracklist.items[index + 1].play_time - diff;
                    } else {
                        remain = showTracklist.current_duration - showtrack.play_time;
                    }
                    setTimeoutId(setTimeout(() => {
                        showTracklist.current_id++;
                        setShowTracklist(showTracklist);
                        setUpdateTrack(true);
                    }, remain * 1000 + 1000));
                    setRemainTime(remain);
                } else if (showTracklist.current_id == 0 && showtrack.id == 1) {
                    const remain = showtrack.play_time - showTracklist.current_play_time;
                    setTimeoutId(setTimeout(() => {
                        showTracklist.current_id++;
                        setShowTracklist(showTracklist);
                        setUpdateTrack(true);
                    }, remain * 1000 + 1000));
                    setRemainTime(remain);
                }
            });
        }
    }, [updateShowTrack, showTracklist, setShowTracklist]);
    return (
        <div id="NowPlayingPanel">
        {track ? 
            <div className="nowplaying">
                <div>
                    <span className="nowplaying--label">{t('artist')}:</span>
                    <span className="nowplaying--value" dangerouslySetInnerHTML={{__html: highlightArtists(track.artist) }}></span>
                </div>
                <div>
                    <span className="nowplaying--label">{t('title')}:</span>
                    <span className="nowplaying--value" dangerouslySetInnerHTML={{__html: fetchAndHighlightRemixer(track.title) }}></span>
                </div>
                {track.show_tracklist && 
                <>
                    <ul id="ShowTracklist">
                        {showTracklist && showTracklist.items.map((showtrack, index, array) => (
                            <React.Fragment key={showtrack.name}>
                                <li className={
                                    showTracklist.current_id == showtrack.id 
                                        ? "st--current" 
                                        : "st--track"}>
                                {showtrack.id == 1 && showTracklist.current_id == 0
                                    ? <><span>{t('starts-in')}: <Countdown key={remainTime} css="st--time" initialCountdown={remainTime} /></span><br/></>
                                    : <></>} 
                                {showTracklist.current_id == showtrack.id 
                                            ? <Countdown key={remainTime} css="st--time" initialCountdown={remainTime} />
                                            : <span className="st--time">{secondsToTime(showtrack.play_time)}</span>}
                                    <span className="st--name">: {limitString(showtrack.name)}</span>
                                </li>
                            </React.Fragment>
                        ))}
                    </ul>
                </>
                }
                {!track.show_tracklist &&
                <>
                    <div>
                        <span className="nowplaying--label">{t('buy')}:</span>
                        <span className="nowplaying--value"><MusicStores artist={track.artist} title={track.title} /></span>
                    </div>
                </>
                }
                {track.reactions.likes.length > 0 &&
                <>
                    <div>
                        <span className="nowplaying--label">{t('liked')} ({track.reactions.likes.length}):</span>
                        <span className="nowplaying--value">{track.reactions.likes.join(" ")}</span>
                    </div>
                </>
                }
                {track.reactions.dislikes.length > 0 &&
                <>
                    <div>
                        <span className="nowplaying--label">{t('disliked')} ({track.reactions.dislikes.length}):</span>
                        <span className="nowplaying--value">{track.reactions.dislikes.join(", ")}</span>
                    </div>
                </>
                }
            </div>
            : <></>}
        </div>
    );
}

function MusicStores({artist, title}) {
    const sanitizedName = `${artist} - ${title}`.replace(/ /g, '+');
    return (
        <>
        <div className='nowplaying--buylinks'>
            <a href={`https://www.beatport.com/search?q=${sanitizedName}`} target="window">Beatport</a>
            <a href={`https://www.junodownload.com/search/?q%5Ball%5D%5B%5D=${sanitizedName}&solrorder=relevancy`} target="window">JunoDownloads</a>
            <a href={`https://www.google.com/search?client=firefox-b-d&q=${sanitizedName}+Music`} target="window">Google</a>
        </div>
        </>
    );
}
