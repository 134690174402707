import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Radiost from './components/Radiost';
import ErrorBoundary from './components/ErrorBoundary';
import './scss/index.scss';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Suspense fallback={<></>}>
        <ErrorBoundary>
            <React.StrictMode>
                <Radiost />
            </React.StrictMode>
        </ErrorBoundary>
    </Suspense>,
);
