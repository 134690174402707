import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { useTranslation } from "react-i18next";
import { MdOutlineFiberNew } from "react-icons/md";
import ReactToolTip from "react-tooltip";
import { toast } from 'react-toastify';
import { AiFillLike as LikeIcon, AiFillDislike as DislikeIcon } from "react-icons/ai";
import { useTrack } from './TrackProvider';
import Countdown from './Countdown';
import { PromiseFetch } from './promise-fetch';
import '../scss/Tracklist.scss';

export default function Tracklist() {
    const { t } = useTranslation();
    const { tracklist, setTracklist } = useTrack();
    const [ next_track, setNextTrack ] = useState(true);
    const [ timeoutId, setTimeoutId ] = useState();

    useEffect(() => {
        if (!next_track) return;
        setNextTrack(false);
        fetch(process.env.REACT_APP_API_URL + '/queue/list', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(response => {
                ReactToolTip.rebuild();
                if (response.remaining && response.remaining > 0) {
                    response.remaining += 5;
                    setTimeoutId(setTimeout(setNextTrack, response.remaining * 1000, true));
                } else {
                    setTimeoutId(setTimeout(setNextTrack, 3000, true));
                }
                setTracklist(response);
            })
            .catch(console.error);
    }, [next_track, tracklist, setTracklist]);

    const handleTracklistVisibilityChange = () => {
        if (!document.hidden) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setNextTrack(true);
        }
    }
    document.addEventListener("visibilitychange", handleTracklistVisibilityChange);

    return (
        <div id="Tracklist">
            <div className="tracklist">
            {tracklist.items.map((track) => (
                <React.Fragment key={track.artist + ' - ' + track.title + track.play_time}>
                    {track['new'] ?
                        <IconContext.Provider value={{size: "1.6em"}}>
                            <div
                                className="tracklist--icons icon-new"
                                data-tip={t('recently added')}
                                >
                                <MdOutlineFiberNew />
                            </div>
                        </IconContext.Provider>
                        : <div></div>
                    }
                    <Reactions
                        t={t} id={track.id}
                        opacity={track.opacity} type='dislike'
                        active={track.current || track.last}
                        count={track.reactions ? track.reactions.dislikes:0}
                        user_reacted={track.reactions ? track.reactions.user_reacted == 'dislike' : false}
                        setNextTrack={setNextTrack}/>
                    <TracklistItem track={track} />
                    {track.current 
                        ? <Countdown key={tracklist.remaining} css="tracklist--timer" initialCountdown={tracklist.remaining} />
                        : <PlayTime
                            play_time={track.play_time}
                            opacity={track.opacity} />
                    }
                    <Reactions
                        t={t} id={track.id}
                        opacity={track.opacity} type='like'
                        active={track.current || track.last}
                        count={track.reactions ? track.reactions.likes:0}
                        user_reacted={track.reactions ? track.reactions.user_reacted == 'like' : false}
                        setNextTrack={setNextTrack}/>
                </React.Fragment>
            ))}
            </div>
        </div>
    );
}

function TracklistItem(props) {
    const track = props.track;
    const trackName = track.artist + ' - ' + track.title;
    const itemClasses = "tracklist--item" + (track.current ? " tracklist--item-current" : "");
    const artist = <div className="tracklist--artist">{track.artist}</div>;
    const title = <div className="tracklist--title">{track.title}</div>;
    return (
        <div className={itemClasses} style={{ opacity: track.opacity }} data-tip={trackName}>
            {track.current && <>{artist}{title}</>}
            {!track.current && <div className="tracklist--non-current">{track.artist} - {track.title}</div>}
        </div>
    );
}

function PlayTime(props) {
    return (
        <div className="tracklist--time" style={{ opacity: props.opacity }}>
        {
            new Intl.DateTimeFormat("en-GB", {
                hour: "2-digit",
                minute: "2-digit"
            }).format(new Date(props.play_time))}
        </div>
    );
}

function Reactions({t, id, type, active, opacity, count, user_reacted, setNextTrack}) {
    const reaction = (id) => {
        toast.promise(new PromiseFetch('/tracks/reaction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                credentials: 'include',
                body: JSON.stringify({ track: id, name: type })
            }), {
            pending: t('adding-reaction'),
            success: {
                render() {
                    setNextTrack(true);
                    return t('reaction-added');
                }
            },
            error: {
                render({data}) {
                    if (!data) {
                        data = t('couldnt-add-reaction');
                    }
                    return data;
                }
            }
        });
    }
    const buttonClasses = "tracklist--icons icon-reaction" + (user_reacted ? " icon-reaction--user-reacted" : "") + (!active ? " icon-reaction--disabled" : "");
    return (
        <>
            <IconContext.Provider value={{size: "20"}}>
                <button
                    onClick={() => reaction(id)}
                    className={buttonClasses}
                    style={{opacity: opacity}}
                    data-tip={t(type) + ' ' + (count ? count : 0)}
                    disabled={!active}
                    >
                {type == 'like' &&
                    <>
                    <LikeIcon />
                    <div className="reaction-count reaction-count--like">
                        {count > 0 &&
                            <div>{count > 9 ? '+' : count}</div>
                        }
                    </div>
                    </>
                }
                {type == 'dislike' &&
                    <>
                    <DislikeIcon />
                    <div className="reaction-count reaction-count--dislike">
                        {count > 0 &&
                            <div>{count > 9 ? '+' : count}</div>
                        }
                    </div>
                    </>
                }
                </button>
            </IconContext.Provider>
        </>
    );
}
