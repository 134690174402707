import { useTranslation } from 'react-i18next';

export default function ErrorScreen({ error }) {
    const { t } = useTranslation();
    return (
        <div class="error">
            <span dangerouslySetInnerHTML={{__html: t('error_screen', { joinArrays: '' })}}>
            </span>
            <p>{t('error').toUpperCase()}: {error.message}</p>
            <a href="/">{t('go_home')}</a>
        </div>
    );
}
