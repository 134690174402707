import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormikField } from './FormikField';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Bottom from './Bottom';
import Header from './Header';
import { useUser } from './UserProvider';
import '../scss/Login.scss';

export default function Login() {
    return (
        <>
            <Header />
            <ResetPassword />
            <Bottom />
        </>
    );
}

function ResetPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user, refreshUser } = useUser();
    const token = searchParams.get('token');
    const [ isReseted, setIsReseted ] = useState(false)
    const [ result, setResult ] = useState()
    const [ error, setError ] = useState()
    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API_URL + '/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({email: values.email})
        })
        .then(response => {
            if (response.ok) {
                setIsReseted(true);
            } else {
                return response.json();
            }
        })
        .then(response => {
            if (response.detail) {
                setError(JSON.stringify(response['detail']));
            } else {
                setError('Error');
            }
        })
        .catch(error => {
            setError(error);
        })
        .finally(() => setSubmitting(false));
    };
    return (

        <div id="ResetPassword" className="authform">
        {!isReseted &&
            <>
            <h2>{t('password-reset')}</h2>
            <Formik 
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('invalid-email'))
                        .required('Required')
                })}
                onSubmit={handleSubmit}
            >
            {({ isSubmitting }) => (
            <Form>
                <div className="auth--error">{error}</div>

                <FormikField name="email" label={t('email')} type="email" />

                <div className="auth--button">
                    <button type="submit" disabled={isSubmitting}>{t('reset')}</button>
                </div>
            </Form>
            )}
            </Formik>
            </>
        }
        {isReseted &&
            <p>{t('password-reset-request')}</p>
        }
        </div>

    );
}
