import { useTranslation } from 'react-i18next';
import '../scss/Disclamer.scss';

export default function Disclamer() {
    const { t } = useTranslation();
    return (
        <div id="Disclamer">
            <div className="disclamer-text" dangerouslySetInnerHTML={{__html: t('disclamer', { joinArrays: '' })}}></div>
        </div>
    )
}
