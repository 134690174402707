import Player from './player/Player';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconContext } from "react-icons";
import { FaTelegramPlane } from "react-icons/fa";
import { HiMiniRadio } from "react-icons/hi2";
import "../scss/Connection.scss";

export default function Connection() {
    const { t, i18n } = useTranslation();
    return (
        <IconContext.Provider value={{size: "29"}}>
        <div id="Connection">
            <div className="connection--common connection--welcome">{t('welcome')}</div>
            <div className="connection--common">
                <div className="connection--how-to-listen"><HiMiniRadio /><div><Link to="how-to-listen">{t('how-to-listen-title')}</Link></div></div>
                <div className="connection--telegram"><FaTelegramPlane /><div dangerouslySetInnerHTML={{__html: t('telegram', { joinArrays: '' })}}></div></div>
            </div>
            <div id="Player">
                <Player />
            </div>
        </div>
        </IconContext.Provider>
    );
}
