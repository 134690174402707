import { useEffect, useCallback } from 'react';
import { IconContext } from "react-icons";
import { FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUser } from './UserProvider';
import {request_veriry_token} from './SignIn';
import '../scss/Header.scss';
import logo from './img/logo.svg'

export default function Header() {
    return (
        <div id="Header">
            <Logo />
            <UserAccount />
            <Languages />
        </div>
    );
}

function Logo() {
    return (
        <div id="logo">
            <Link to="/"><img src={logo} alt="" className="logo--sub"/></Link>
        </div>
    );
}

function UserAccount() {
    const { t } = useTranslation();
    const { user, refreshUser } = useUser();
    useEffect(() => {
        refreshUser();
    }, []);

    const logoutHandler = () => {
        fetch(process.env.REACT_APP_API_URL + '/logout', {
            method: 'POST',
            credentials: 'include'
        })
            .then(response => {
                if (response.ok) {
                    refreshUser();
                }
            });
    };

    const sendVerificationHandler = () => {
        if (user && user.email) {
            toast.promise(request_veriry_token(user.email), {
                pending: t('sending-verification'),
                success: t('verification-sent'),
                error: {
                    render({data}) {
                        if (!data) {
                            data = t('couldnt-sent-verification');
                        }
                        return data;
                    }
                }
            });
        }
    };

    return (
        <div className="useraccount">
            {user &&
                <>
                    <IconContext.Provider value={{size: "1.6em"}}>
                        <FaUserCircle />
                    </IconContext.Provider>
                    <div className="useraccount--name">
                        {user.username}
                    </div>
                    {!user.is_verified && 
                        <button
                            className="useraccount--verification"
                            onClick={sendVerificationHandler}>
                                {t('verify-email')}
                        </button>}
                    <button className="useraccount--logout" onClick={logoutHandler}>
                        {t('logout')}
                    </button>
                </>
            }
            {!user &&
                <div className="useraccount--links">
                    <Link to="/login">{t('login')}</Link>
                    <Link to="/signin">{t('sign-in')}</Link>
                </div>
            }
        </div>
    );
}

function Languages() {
    const { i18n } = useTranslation();
    const { user } = useUser();
    const changeLanguage = useCallback((code, update_user) => {
        i18n.changeLanguage(code);
        if (update_user) {
            fetch(process.env.REACT_APP_API_URL + '/user/update', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                credentials: 'include',
                body: JSON.stringify({
                    language: code
                })
            });
        }
    }, [i18n]);
    useEffect(() => {
        if (user && user.language) {
            changeLanguage(user.language, false);
        }
    }, [user, changeLanguage]);
    return (
        <ul className="languages">
            <li><button type="button" onClick={() => changeLanguage('en', true)}>EN</button></li>
            <li><button type="button" onClick={() => changeLanguage('ru', true)}>РУС</button></li>
        </ul>
    );
}
