import { useTranslation } from 'react-i18next';
import Bottom from './Bottom';
import Header from './Header';
import '../scss/HowToListen.scss';
import tunerImg from './img/tuner.jpg';
import appStore from './img/get-on-apple-store.svg';
import playStore from './img/get-on-play-store.svg';

export default function HowToListen() {
    return (
        <>
        <div id='Content'>
            <Header />
            <HowToListenForm />
        </div>
            <Bottom />
        </>
    );
}

function HowToListenForm() {
    const { t, } = useTranslation();
    return (
        <div id='HowToListen'>
        <div className="htl-content" dangerouslySetInnerHTML={
            {
                __html: t('how-to-listen', {
                        joinArrays: '',
                        tunerImg: tunerImg,
                        appStore: appStore,
                        playStore: playStore
                    })
            }}>
            </div>
        </div>
    );
}
