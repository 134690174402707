import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Bottom from './Bottom';
import Header from './Header';
import OAuth from './OAuth';
import { FormikField } from './FormikField';
import '../scss/Login.scss';

export default function Login() {
    return (
        <>
        <div id='Content'>
            <Header />
            <LoginForm />
        </div>
            <Bottom />
        </>
    );
}

function LoginForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ error, setError ] = useState();
    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        let formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        fetch(process.env.REACT_APP_API_URL + '/login', {
            method: 'POST',
            credentials: 'include',
            body: new URLSearchParams(formData)
        })
            .then(response => response.status == 200 ? navigate('/') : response.json())
            .then(response => response && setError(JSON.stringify(response['detail'])))
            .catch(error => setError(error))
            .finally(() => setSubmitting(false));
    };
    return (
        <div id="Login" className="authform">
            <h2>{t('enter-username-password')}</h2>
            <Formik
                initialValues={{ 
                    username: '',
                    password: ''}}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .min(3, t('must-be-3-30-char'))
                        .max(30, t('must-be-3-30-char'))
                        .required(t('required')),
                    password: Yup.string()
                        .min(8, t('must-be-8-50-char'))
                        .max(50, t('must-be-8-50-char'))
                        .required(t('required')),
                })}
                onSubmit={handleSubmit}
            >
            {({ isSubmitting }) => (
            <Form>
                <div className="auth--error">{error}</div>
                <FormikField name="username" label={t('username-or-email')} type="text" />
                <FormikField name="password" label={t('password')} type="password" custom={ <Link className="auth--reset-password" to="/reset-password">{t('password-reset')}</Link> } />
                <div className="auth--button">
                    <button type="submit" disabled={isSubmitting}>{t('login')}</button>
                </div>
            </Form>
            )}
            </Formik>
            <OAuth/>

        </div>
    )
}
