import { useTranslation } from "react-i18next";
import '../scss/Bottom.scss';

export default function Bottom() {
    const { t } = useTranslation();
    return (
        <div id="Bottom">
            <div className="bottom--bg-attribution"><a href='https://www.freepik.com/photos/pattern'>Background by rawpixel.com - www.freepik.com</a></div>
            <div className="bottom--copyright">Coldstar Radio 2022 – 2024</div>
            <div className="bottom--contacts">
                <ul>
                    <li>
                        <a href="/privacy">{t('privacy-policy')}</a>
                    </li>
                    <li>
                        {t('contact')}: <a href="mailto:admin@coldstar.online">@</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
