import React, { createContext, useCallback, useContext, useState } from 'react';

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export function UserProvider({ children }) {
    const [ user, setUser ] = useState(undefined);
    const refreshUser = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + '/user/me', {
            credentials: 'include'
        })
        .then(response => {
            if (response.ok) return response.json();
            setUser(undefined);
        })
        .then(response => {
            setUser(response);
        });
    });
    return (
        <UserContext.Provider value={{ user, refreshUser }}>
            {children}
        </UserContext.Provider>
    );
};
